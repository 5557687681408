
import { defineComponent, inject } from 'vue'
import { registerTheme } from 'echarts/core'
import { mapState } from 'pinia'
import LoadingAppAnimation from '@/components/Animations/LoadingAppAnimation.vue'

import mainTheme from '@/assets/data/echartsThemes/derivato.project.json'

import { useAppDataStore } from '@/stores/data/AppDataStore'

registerTheme('mainTheme', mainTheme.theme)

export default defineComponent({
	name: 'App',
	components: {
		LoadingAppAnimation,
	},
	inject: ['fancyBrand'],
	data() {
		return {
			appDataStore: useAppDataStore(),
			brandName: String(this.fancyBrand),
		}
	},
	computed: {
		...mapState(useAppDataStore, ['isAppLoading']),
		showAppBackground() {
			if (this.$route.meta.layout === 'plain') return false
			return true
		},
	},
	mounted() {
		if(this.$isDarkTheme){
			document.body.className += 'dark'
		}
		document.title = this.brandName
	},
})
